<template>
    <div>
        <h3 class="title is-4">Emergency Contacts
            <button class="button is-success is-small" @click="showAddForm = true" :disabled="loading==true || working==true" v-if="showAddForm == false">Add New Contact</button>
            <button class="button is-danger is-small" @click="showAddForm = false" :disabled="loading==true || working==true" v-if="showAddForm == true">Exit without Saving</button>

        </h3>
        
        <div class="notification is-warning" v-if="loading==true">Loading emergency contacts ... </div>
        <div class="notification is-info" v-if="loading==false && emergency_contacts.length == 0 && this.showAddForm == false">There are no emergency contacts to show. </div>
        <table class="table is-fullwidth is-hoverable" v-if="loading==false && emergency_contacts.length > 0 && this.showAddForm == false" >
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Relationship</th>
                    <th>Home Telephone</th>
                    <th>Work Telephone</th>
                    <th>Mobile Telephone</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in emergency_contacts" :key="row.id">
                    <td>{{ row.forename}} {{row.surname}}</td>
                    <td>{{ row.relationship}}</td>
                    <td>{{ row.home_telephone}}</td>
                    <td>{{ row.work_telephone}}</td>
                    <td>{{ row.mobile_telephone}}</td>
                    <td ><button class="button is-danger is-small" :disabled="working==true" @click="updateEmergencyContact(row.member_id, row.id)">Delete</button></td>
                </tr>
                
            </tbody>
        </table>

        <div class="box" v-if="this.showAddForm == true">	
              <form @submit.prevent="saveMemberEmergencyContact()">
                   
                      <div class="field" >
                          <label class="label">Forename</label>
                          <div class="control">
                              <input class="input" type="text" v-model="forename"  required>
                          </div>
                      </div>
                      <div class="field" >
                          <label class="label">Surname</label>
                          <div class="control">
                              <input class="input" type="text" v-model="surname"  >
                          </div>
                      </div>
                      <div class="field" >
                          <label class="label">Relationship</label>
                          <div class="control">
                              <input class="input" type="text" v-model="relationship"  required>
                          </div>
                      </div>
                      <div class="field" >
                          <label class="label">Mobile Telephone</label>
                          <div class="control">
                              <input class="input" type="text" v-model="mobile_telephone"  >
                          </div>
                      </div>
                      <div class="field" >
                          <label class="label">Home Telephone</label>
                          <div class="control">
                              <input class="input" type="text" v-model="home_telephone"  >
                          </div>
                      </div>
                      <div class="field" >
                          <label class="label">Work Telephone</label>
                          <div class="control">
                              <input class="input" type="text" v-model="work_telephone"  >
                          </div>
                      </div>
                      <div class="field" >
                          <label class="label">Notes</label>
                          <div class="control">
                              <textarea class="textarea"  v-model="notes"  ></textarea>
                          </div>
                      </div>

                      <div class="field is-grouped" >
                          <div class="control">
                              <button type="submit" class="button is-success" :disabled="working===true">Save Emergency Contact</button>
                          </div>
                      </div>
                  </form>
            
          </div>

    </div>
</template>

<script>
import MemberMixin from '@/mixins/MemberMixin.js';

import MemberService from '@/services/MemberService.js';
import UtilityService from '@/services/UtilityService.js';

export default {
    name: 'EmergencyContacts',
  data() {
    return {
      loading: true,
      working: false,
      emergency_contacts: [],
      showAddForm: false,
      forename: '',
      surname: '',
      relationship: '',
      mobile_telephone: '',
      home_telephone: '',
      work_telephone: '',
      notes: ''
      
    }
  },
  mixins: [MemberMixin],
  props: ['member_id'],
  methods: {
        
       
        async getMemberEmergencyContacts() {
            this.loading=true
            UtilityService.getSimpleApiData(this.accessToken, "members/" + this.member_id + "/emergency_contacts")
            .then(
                (emergency_contacts => {
                    this.$set(this, "emergency_contacts", emergency_contacts);
                    this.loading=false
                    this.working = false

                    this.forename = ''
                    this.surname = ''
                    this.relationship = ''
                    this.mobile_telephone = ''
                    this.home_telephone = ''
                    this.work_telephone = ''
                    this.notes = ''
                }).bind(this)
            );
        },
        async updateEmergencyContact(member_id, emergency_contact_id) {
			this.working = true

            if(confirm("Are you sure you want to delete this contact ?"))
            {
            
                var formdata = { 
                    _method: 'patch',
                    active : 0
                }; 

                MemberService.updateMemberEmergencyContact(this.accessToken, formdata, member_id, emergency_contact_id)
                .then((response) => {
                    this.getMemberEmergencyContacts()
                    console.log(response)
                    this.working = false
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false
                });
            }
            else{
                this.working = false;
                return false
            }
        },
        async saveMemberEmergencyContact() {
                this.working = true
                var formdata = { 
                   
                   member_id: this.member_id,
                   forename: this.forename,
                   surname: this.surname,
                   relationship: this.relationship,
                   mobile_telephone: this.mobile_telephone,
                   home_telephone: this.home_telephone,
                   work_telephone: this.work_telephone,
                   notes: this.notes

                    
                }; 

                MemberService.saveMemberEmergencyContact(this.accessToken, this.member_id, formdata)
                .then((response) => {
                    
                    this.getMemberEmergencyContacts()
                    this.showAddForm = false
                    console.log(response)
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false
                });
            },
      
        
    },
    created() {
            this.getAccessToken()
            .then(() => { 
                this.getMemberEmergencyContacts()
            });
        
    },
    computed : {
       
    }
}
</script>